/**
 * Helper class to do common analytics tracking across both GA and Matomo.
 */
var Analytics = /** @class */ (function () {
    function Analytics() {
        this.isGAEnabled = 'gtag' in window;
        this.isMatomoEnabled = '_paq' in window;
        // @ts-ignore
        this.gtag = this.isGAEnabled ? window.gtag : function () { };
        // @ts-ignore
        this.paq = this.isMatomoEnabled ? window._paq : [];
    }
    Analytics.prototype.trackPageView = function () {
        this.paq.push(['trackPageView']);
        this.gtag('event', 'page_view');
    };
    Analytics.prototype.trackSiteSearch = function (keyword, category, searchCount) {
        this.paq.push(['trackSiteSearch', keyword, category, searchCount]);
        this.gtag('event', 'site_search', { keyword: keyword, category: category, searchCount: searchCount });
    };
    Analytics.prototype.trackEvent = function (category, action, name, value) {
        this.paq.push(['trackEvent', category, action, name, value]);
        this.gtag('event', action, { event_category: category, event_name: name, value: value });
    };
    return Analytics;
}());
export { Analytics };
var analytics = new Analytics();
export default analytics;
